<template>
  <v-row class="d-inherit">
    <v-col class="pt-0">
      <div class="report30__custom-table-wrapper">
        <div v-if="pending" class="px-3 mt-4 h5">Yuklanmoqda...</div>

        <template v-else>
          <table class="report30__table">
            <thead>
              <tr class="flex-nowrap m-0 custom__table__sticky__header">
                <th
                  class="px-4 font-weight-bold order-title__sticky sticky__top sticky__left table__td__white"
                  :cols="listViewProps.headerCol"
                  style="z-index: 2"
                >
                  Ko'rsatkichlar
                </th>
                <th
                  class="px-4 text-center order-title__sticky sticky__top table__td__white font-weight-bold total__value__col"
                  v-for="item in monthCount"
                  :key="item + 1"
                >
                  {{ months[item - 1] }}
                </th>

                <th
                  class="px-4 text-center order-title__sticky sticky__top table__td__white font-weight-bold total__value__col"
                  style="z-index: 2"
                >
                  Itogo
                </th>
              </tr>
            </thead>

            <!-- list -->
            <tbody>
              <tr
                class="flex-nowrap m-0 align-items-center r30__table__item"
                v-for="listItem in list"
                :class="{ table__row__primary: listItem.deep == 0 }"
                :key="listItem.id"
              >
                <td
                  class="order-title__sticky sticky__left px-4 py-1"
                  :class="[
                    listItem.deep == 0
                      ? 'table__td__primary'
                      : 'table__td__white'
                  ]"
                >
                  <div>
                    <b
                      class="text-overflow-3-line"
                      v-if="listItem.deep == 0"
                      :title="listItem.description"
                      >{{ listItem.description }}</b
                    >
                    <p
                      class="mb-0 pl-5 text-overflow-3-line"
                      v-else-if="listItem.deep == 1"
                      :title="listItem.description"
                    >
                      {{ listItem.description }}
                    </p>
                  </div>
                </td>

                <td
                  class="text-center total__value__col"
                  :title="toRoundFloat(total)"
                  v-for="total in listItem.months_dict"
                  :key="total + 1"
                >
                  {{ toRoundFloat(total) }}
                </td>

                <td class="text-center total__value__col">
                  {{ toRoundFloat(listItem.total) }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import paginationMixin from '@/mixins/pagination.mixin.js'
import monthNames from '@/constants/js/month.names.js'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { takeFilledKeyValue, toRoundFloat } from '@/utils'

export default {
  data() {
    return {
      monthCount: 12,

      listViewProps: {
        headerCol: 2,
        bodyCol: 1
      },
      filterFormProps: {},

      list: [],
      pending: false
    }
  },

  mixins: [paginationMixin],

  computed: {
    ...mapState({
      reportsData: (state) => state.reportsStore.reportsData || {},
      table_loading: (state) => state.reportsStore.table_loading || false
      // list: state => (state.reportsStore.reportsData || {}).results || []
    }),

    isLoading() {
      return this.$store.state.requests.isLoading
    },

    months() {
      return monthNames
    }
  },

  methods: {
    ...mapActions(['report30']),

    toRoundFloat,

    fetchData() {
      this.pending = true
      const routeParmas = takeFilledKeyValue(this.$route.query)

      // eslint-disable-next-line no-unused-vars
      const { page, limit, offset, tab, ...otherProps } = routeParmas

      this.report30({
        // endpoint: tab || 'income',
        params: {
          ...(otherProps || {})
        }
      })
        .then((res) => {
          // console.log('res', res)
          this.pending = false

          if (res && res.data && res.data.results) {
            let t = []

            res.data.results.forEach(({ children, ...item }) => {
              t.push(item)

              if (children) {
                // eslint-disable-next-line no-unused-vars
                children.forEach(({ children, ...subItem }) => {
                  t.push(subItem)
                })
              }
            })

            this.list = t
          }
        })
        .catch(() => {
          this.pending = false
        })
    },

    setTableTab(tabName) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          tab: tabName
        }
      })
    }

    // getSummaries(param) {
    //   // eslint-disable-next-line no-unused-vars
    //   const { columns, data } = param
    //   const sums = []
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       sums[index] = 'Itogo'
    //       return
    //     }
    //     sums[index] = ''

    //     if (index == columns.length - 1) {
    //       sums[index] = this.reportsData.page_summa
    //     }
    //   })

    //   return sums
    // }
  },

  watch: {
    $route: {
      handler: 'fetchData',
      immediate: true
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Moliyaviy hisobot' }])
    })
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>

<style>
:root {
  --custom_table_background_color: #fff;
}
.reports-toolbar {
  height: 50px !important;
}

.report30_ul__container {
  list-style: none;
}
.r30-ul__list-item {
  list-style: none;
  /* padding-left: 20px; */
}

.report30__custom-table-wrapper {
  background-color: var(--custom_table_background_color, #fff);
  overflow: hidden;
}

table.report30__table {
  display: block;
  height: calc(100vh - 300px);
  width: calc(100vw - 292px);
  overflow: auto;
}
/* table.report30__table thead,
table.report30__table tbody {
  overflow: auto;
  display: block;
  width: calc(100vw - 292px);
} */

/* table.report30__table tbody {
  height: calc(100vh - 300px);
} */

.order-title__sticky {
  position: sticky;
}

.order-title__sticky.sticky__top {
  top: 0;
}

.order-title__sticky.sticky__left {
  left: 0;
}
/* .r30__table__item:nth-child(even) {
  background-color: #ececec;
} */

.r30__table__item.table__row__primary,
.table__td__primary {
  background-color: #ececec;
}

.table__td__white {
  background-color: var(--custom_table_background_color, #fff);
}

.r30__table__item,
.table__td__primary,
.table__td__white {
  border-bottom: 1px solid #ddd;
}

.custom__table__sticky__header {
  background-color: var(--custom_table_background_color, #fff);
  /* position: sticky;
  top: 0; */
}

.total__value__col {
  min-width: 110px;
}

.text-overflow-3-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
